import { GeneralProposalIsEntity } from "./GeneralProposalIsEntity"
import { GenProposalTariffItemTierEntity } from "./GeneralProposalTariffItemTierEntity"

export interface GeneralProposalItemEntity {

    key: string,
    priority?: number | null,
    seq?: number | null,
    proposalId?: number | null,
    tariffType?: string | null,
    tariffCode?: string | null,
    forwarderCode?: string | null,
    master?: Date | null,
    tariffCodeDesc?: Date | null,
    tierList?: GenProposalTariffItemTierEntity[]  | null,
    stdIsList?: GeneralProposalIsEntity[]  | null,

    [key: string]: string | boolean | number | Date | null | undefined | Object
}

export const EMPTY_GENERAL_PROPOSAL_ITEM_ENTITY: GeneralProposalItemEntity = {

    key: "",
    priority: null,
    seq: null,
    proposalId: null,
    tariffType: null,
    tariffCode: null,
    forwarderCode: null,
    master: null,
    tariffCodeDesc: null,
    tierList: [],
    stdIsList: [],
}