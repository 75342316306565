import { EMPTY_GENERAL_PROPOSAL_DETAIL_MODEL } from "presentation/model/GeneralProposal/GeneralProposalDetailModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: GeneralProposalDetailProvider,
    useTracked: useGeneralProposalDetailTracked
} = createContainer(() => useState(EMPTY_GENERAL_PROPOSAL_DETAIL_MODEL), {concurrentMode: true});
export { GeneralProposalDetailProvider, useGeneralProposalDetailTracked };

