import { EMPTY_GENERAL_PROPOSAL_MODEL } from "presentation/model/GeneralProposal/GeneralProposalModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: GeneralProposalProvider,
    useTracked: useGeneralProposalTracked
} = createContainer(() => useState(EMPTY_GENERAL_PROPOSAL_MODEL), {concurrentMode: true});
export { GeneralProposalProvider, useGeneralProposalTracked };

