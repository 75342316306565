import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { GeneralProposalDetailProvider } from "presentation/store/GeneralProposal/GeneralProposalDetailProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import GeneralProposalDetailMaintenance from "presentation/view/section/GeneralProposal/Detail/GeneralProposalDetailMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const GeneralProposalDetailContMain = () => {
    return <ANAInfoWrapper permission={Permission.GENERAL_PROPOSAL_DETAIL_MAINTENANCE}>
        <MessageBarWrapper>
            <GeneralProposalDetailProvider>
                <GridStyles/>
                <GeneralProposalDetailMaintenance/>
            </GeneralProposalDetailProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default GeneralProposalDetailContMain;