
export interface GeneralProposalSearchCriteria{
    tariffTypeList?: string[] | null,
    tariffCodeList?: string[] | null,
    statusList?: string[] | null,
    effectiveDateFrom?: Date | null,
    effectiveDateTo?: Date | null,  
    
    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_GENERAL_PROPOSAL_SEARCH_CRITERIA : GeneralProposalSearchCriteria = {

    tariffTypeList: [],
    tariffCodeList: [],
    statusList: [],
    effectiveDateFrom: null,   
    effectiveDateTo: null,   
}