import _ from "lodash";
import { GeneralProposalConstant, generalProposalRequiredFieldList } from "presentation/constant/GeneralProposal/GeneralProposalConstant";
import { useGeneralProposalDetailVM } from "presentation/hook/GeneralProposal/useGeneralProposalDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useGeneralProposalDetailTracked } from "presentation/store/GeneralProposal/GeneralProposalDetailProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useCallback, useMemo, useState } from "react";
import { FieldType, HPHBreadcrumb, IconButton, IFieldValue, InputDropdown, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, SidebarTitle, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const GeneralProposalHeaderFormPanel = () => {
    const [generalProposalDtlState] = useGeneralProposalDetailTracked();
    const generalProposalDtlVM = useGeneralProposalDetailVM();
    const messageBarVM = useMessageBarVM();
    const { isShowEditPanel, currentGeneralProposal, generalProposalItemTierList, generalProposalItemIsList } = generalProposalDtlState;
    const GENERAL_PROPOSAL_CONSTANT = GeneralProposalConstant.Table;

    const [isLoading, setIsLoading] = useState(false);
    const handleReset = useCallback(async () => {
        generalProposalDtlVM.onResetClick();
    }, [generalProposalDtlVM]);

    const memoTariffCodeOptions = useMemo(() => {
        return currentGeneralProposal.tariffType
            ? generalProposalDtlState.dynamicOptions.tariffCodeDropdownOptions[currentGeneralProposal.tariffType]
            : [];
    }, [currentGeneralProposal.tariffType, generalProposalDtlState.dynamicOptions.tariffCodeDropdownOptions]);

    const memoTarType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "15px", marginTop: "15px" }}>
            <InputDropdown
                label={GENERAL_PROPOSAL_CONSTANT.TARIFF_TYPE}
                width='180px'
                inputType="freeText"
                mode={'single'}
                sort={false}
                value={currentGeneralProposal.tariffType}
                onChange={(e) => generalProposalDtlVM.onHeaderSingleDropdownChange(e, 'tariffType')}
                options={generalProposalDtlState.dynamicOptions.tariffTypeDropdownOptions} />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.TARIFF_TYPE, currentGeneralProposal.tariffType, generalProposalDtlState.dynamicOptions.tariffTypeDropdownOptions, generalProposalDtlVM])

    const memoTarCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "15px", marginTop: "15px" }}>
            <InputDropdown
                label={GENERAL_PROPOSAL_CONSTANT.TARIFF_CODE}
                width='180px'
                inputType="freeText"
                mode={'single'}
                sort={false}
                value={currentGeneralProposal.tariffCode}
                onChange={(e) => generalProposalDtlVM.onHeaderSingleDropdownChange(e, 'tariffCode')}
                options={memoTariffCodeOptions} />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.TARIFF_CODE, currentGeneralProposal.tariffCode, generalProposalDtlVM, memoTariffCodeOptions])

    const memoEffectiveDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "15px", marginTop: "15px" }}>
            <DatePickerComponent
                disabled={false}
                label={GENERAL_PROPOSAL_CONSTANT.EFFECTIVE_DATE}
                width="180px"
                date={currentGeneralProposal?.effectiveDate}
                fieldName="effectiveDate"
                errorMessage={""}
                onDateChange={generalProposalDtlVM.onHeaderFieldChange} />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.EFFECTIVE_DATE, currentGeneralProposal?.effectiveDate, generalProposalDtlVM.onHeaderFieldChange])

    const memoExpiryDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "15px", marginTop: "15px" }}>
            <DatePickerComponent
                disabled={false}
                label={GENERAL_PROPOSAL_CONSTANT.EXPIRY_DATE}
                width="180px"
                date={currentGeneralProposal?.expiryDate}
                fieldName="expiryDate"
                errorMessage={""}
                onDateChange={generalProposalDtlVM.onHeaderFieldChange} />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.EXPIRY_DATE, currentGeneralProposal?.expiryDate, generalProposalDtlVM.onHeaderFieldChange])

    const memoCurrency = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "15px", marginTop: "15px" }}>
            <InputDropdown
                label={GENERAL_PROPOSAL_CONSTANT.CURRENCY}
                width='180px'
                inputType="freeText"
                mode={'single'}
                sort={false}
                value={currentGeneralProposal.currencyCode}
                onChange={(e) => generalProposalDtlVM.onHeaderSingleDropdownChange(e, 'currencyCode')}
                options={generalProposalDtlState.dynamicOptions.currencyCodeDropdownOptions} />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.CURRENCY, currentGeneralProposal?.currencyCode, generalProposalDtlState.dynamicOptions.currencyCodeDropdownOptions, generalProposalDtlVM])

    const memoStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "15px", marginTop: "15px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentGeneralProposal?.status || 'NEW'}
                fieldValue={currentGeneralProposal?.status}
                fieldLabel={GENERAL_PROPOSAL_CONSTANT.STATUS}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'status'}
                maxLength={60}
                requiredFieldList={generalProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalProposalDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.STATUS, currentGeneralProposal?.status, generalProposalDtlVM])

    // const memoRejectReason = useMemo(() =>
    //     <div className='im-flex-row-item' style={{ width: "395px", marginBottom: "15px", marginTop: "15px" }} >
    //         <NbisCommonField
    //             errorMessages={{}}
    //             isReadOnly={true}
    //             isShowOptional={true}
    //             readOnlyValue={currentGeneralProposal?.rejectReason || ''}
    //             fieldValue={currentGeneralProposal?.rejectReason}
    //             fieldLabel={GENERAL_PROPOSAL_CONSTANT.REJECT_REASON}
    //             isSaveClicked={false}
    //             fieldType={FieldType.TEXTAREA}
    //             fieldKey={'rejectReason'}
    //             maxLength={200}
    //             requiredFieldList={generalProposalRequiredFieldList}
    //             onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
    //                 generalProposalDtlVM.onTextAreaChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)}
    //         />
    //     </div>
    //     , [GENERAL_PROPOSAL_CONSTANT.REJECT_REASON, currentGeneralProposal?.rejectReason, generalProposalDtlVM])

    const memoRemarks = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "395px", marginBottom: "15px", marginTop: "15px" }} >
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentGeneralProposal?.remarks || ''}
                fieldValue={currentGeneralProposal?.remarks}
                fieldLabel={GENERAL_PROPOSAL_CONSTANT.REMARKS}
                isSaveClicked={false}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'remarks'}
                maxLength={200}
                requiredFieldList={generalProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalProposalDtlVM.onTextAreaChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)}
            />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.REMARKS, currentGeneralProposal?.remarks, generalProposalDtlVM])


    const isSaveDisable = () => {
        if (!_.isEmpty(generalProposalItemTierList) && !isShowEditPanel) return false
        return true;
    }

    const recordValidate = useCallback(() => {
        if (!currentGeneralProposal.tariffType) {
            messageBarVM.showError("Tariff Type is mandatory.");
            return false;
        }
        if (!currentGeneralProposal.tariffCode) {
            messageBarVM.showError("Tariff Code is mandatory.");
            return false;
        }
        if (!currentGeneralProposal.effectiveDate) {
            messageBarVM.showError("Effective Date is mandatory.");
            return false;
        }
        if (!currentGeneralProposal.expiryDate) {
            messageBarVM.showError("Expiry Date is mandatory.");
            return false;

        }
        if (!currentGeneralProposal.currencyCode) {
            messageBarVM.showError("Currency is mandatory.");
            return false;
        }
        return true;
    }, [currentGeneralProposal.tariffType, currentGeneralProposal.tariffCode, currentGeneralProposal.effectiveDate, currentGeneralProposal.expiryDate, currentGeneralProposal.currencyCode, messageBarVM])


    const handleSave = useCallback(() => {
        if (recordValidate()) {
            setIsLoading(true);
            generalProposalDtlVM.onSaveAll(currentGeneralProposal, generalProposalItemTierList, generalProposalItemIsList).then((res) => {
                setIsLoading(false);
                if (res?.success) {
                    generalProposalDtlVM.onCloseClick();
                } else {
                    messageBarVM.showError(res.data ?? "Failed in save data.")
                }

            }).catch((error) => {
                setIsLoading(false);
                messageBarVM.showError("Failed in save data.")
            })
        }
    }, [recordValidate, generalProposalDtlVM, currentGeneralProposal, generalProposalItemTierList, generalProposalItemIsList, messageBarVM]);


    return <>
        <Sidebarheader style={{ width: '100%' }}>
            <Breadcrumb>
                <HPHBreadcrumb breadcrumbData={[{ title: GeneralProposalConstant.Agreement.AGREEMENT }, { title: GeneralProposalConstant.Title.GENERAL_PROPOSAL }]} onCurrentClick={() => { }}></HPHBreadcrumb>
            </Breadcrumb>
            <StyledAction>
                <>
                    <IconButton fileName="Icon-reset" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={() => handleReset()} />
                    <IconButton fileName="Icon-tick" size="medium" disabled={isSaveDisable()} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />
                    <IconButton fileName="Icon-cross" size="medium" disabled={isShowEditPanel} toolTipText='Close' toolTipArrow={false} toolTipPlacement="bottom" onClick={generalProposalDtlVM.onCloseClick} />
                </>
            </StyledAction>
        </Sidebarheader >
        <div className={'add-edit-form'}>
            <SidebarTitle>{"NEW GENERAL PROPOSAL"}</SidebarTitle>
            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
            <Sidebarheader style={{ width: '100%' }}>
                <CriteriaItemContainer>
                    {memoStatus}
                    {memoTarType}
                    {memoTarCode}
                    {memoCurrency}
                    {memoEffectiveDate}{memoExpiryDate}
                    {memoRemarks}
                </CriteriaItemContainer>
            </Sidebarheader>
        </div>

    </>
}
