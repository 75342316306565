import { ShiftRepoImpl } from "domain/repository/Common/ShiftRepoImpl";
import { ExchangeRateRepoImpl } from "domain/repository/ExchangeRate/ExchangeRateRepoImpl";
import { GeneralProposalRepoImpl } from "domain/repository/GeneralProposal/GeneralProposalRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { useGeneralProposalDetailTracked } from "presentation/store/GeneralProposal/GeneralProposalDetailProvider";
import { useGeneralProposalTracked } from "presentation/store/GeneralProposal/GeneralProposalProvider";
import { GeneralProposalDetailVM } from "presentation/viewModel/GeneralProposal/GeneralProposalDetailVM";
import { useMemo } from "react";

export const useGeneralProposalDetailVM = () => {
    const [, setGeneralProposalDetailState] = useGeneralProposalDetailTracked();
    const [, setGeneralProposalState] = useGeneralProposalTracked();
    const generalProposalDetailVM = useMemo(() =>
        GeneralProposalDetailVM({
            itemDispatch: [setGeneralProposalDetailState],
            dispatch: [setGeneralProposalState],
            generalProposalRepo: GeneralProposalRepoImpl(),
            exchangeRateRepo: ExchangeRateRepoImpl(),
            standardTariffCodeRepo:StandardTariffCodeRepoImpl(),
            shiftRepo: ShiftRepoImpl()
        }), [setGeneralProposalDetailState, setGeneralProposalState])

    return generalProposalDetailVM
}